import classNames from "components/ui/classNames";
import FeatureBox from "components/conversion/FeatureBox";
import ChristTheRedeemer from "components/icons/ChristTheRedeemer";
import StatueOfLiberty from "components/icons/StatueOfLiberty";
import getHumanReadableLabel from "components/screener/getHumanReadableLabel";
import useSelfie from "lib/hooks/useSelfie";
import { NativeSelect } from "lib/miolo";
import type { MenuProvided } from "react-instantsearch-core";
import { connectMenu } from "react-instantsearch-dom";
import { FormattedMessage, useIntl } from "react-intl";

export default function AssetType() {
  return <AssetTypeMenu attribute="type" />;
}

const AssetTypeMenu = connectMenu<MenuProvided>(({ items, refine, currentRefinement }) => {
  const viewer = useSelfie();
  const intl = useIntl();

  const SORT_BY = [
    intl.formatMessage({ defaultMessage: "Ações", id: "FLGl5o" }),
    intl.formatMessage({ defaultMessage: "FIIs", id: "f9DMbU" }),
    intl.formatMessage({ defaultMessage: "Stocks", id: "6rgkM5" }),
    intl.formatMessage({ defaultMessage: "REITs", id: "QOIFTQ" }),
  ];

  return (
    <div className="space-y-4">
      <div
        className={classNames("space-y-1 font-aeonik", {
          hidden: currentRefinement === null,
        })}
      >
        <h3 className="font-aeonik text-base font-bold uppercase tracking-wide text-zinc-500">
          <FormattedMessage defaultMessage="Filtrar Por" id="WKttF6" />
        </h3>
        <span className="hidden font-aeonik text-sm font-normal italic tracking-wider text-slate-400 lg:flex">
          <FormattedMessage defaultMessage="Escolha um tipo de ativo" id="SH6LNa" />
        </span>
      </div>

      {currentRefinement !== null ? (
        <NativeSelect value={currentRefinement || ""} onChange={(event) => refine(event.currentTarget.value || "")}>
          <option value="">
            <FormattedMessage defaultMessage="Todos" id="P8VfZA" />
          </option>

          {items
            .filter((item) => getHumanReadableLabel(intl, item.label))
            .sort(
              (a, b) =>
                SORT_BY.indexOf(getHumanReadableLabel(intl, a.label) || "") -
                SORT_BY.indexOf(getHumanReadableLabel(intl, b.label) || ""),
            )
            .map((item) => {
              return (
                <option key={item.label} value={item.label}>
                  {getHumanReadableLabel(intl, item.label)}
                </option>
              );
            })}
        </NativeSelect>
      ) : (
        <div className="grid grid-cols-2 gap-6 lg:flex lg:grid-cols-2 lg:flex-col">
          {items
            .filter((item) => getHumanReadableLabel(intl, item.label))
            .sort((a, b) => {
              return (
                SORT_BY.indexOf(getHumanReadableLabel(intl, a.label) || "") -
                SORT_BY.indexOf(getHumanReadableLabel(intl, b.label) || "")
              );
            })
            .map((item) => {
              return (
                <div key={item.label}>
                  <button
                    type="button"
                    aria-label={intl.formatMessage(
                      {
                        defaultMessage: "Filtrar por {label}",
                        id: "U7jGh2",
                      },
                      { label: getHumanReadableLabel(intl, item.label) },
                    )}
                    onClick={() => refine(item.value || "")}
                    className={classNames(
                      "group",
                      "relative cursor-pointer rounded-xl bg-slate-100 p-4 text-left font-aeonik",
                      "border border-transparent",
                      "w-full bg-slate-100 hover:bg-violet-50 hover:text-violet-300",
                      "dark:bg-slate-700 dark:hover:bg-slate-600 dark:hover:ring-slate-950",
                    )}
                  >
                    <div
                      className={classNames(
                        "flex flex-col-reverse items-center justify-center text-center",
                        "md:flex-row md:justify-between",
                      )}
                    >
                      <div className="mt-2 space-y-1 md:mt-0">
                        <h5
                          className={classNames(
                            "flex justify-center text-sm font-bold text-slate-500",
                            "group-hover:text-violet-600",
                            "md:justify-start",
                            "dark:text-slate-300 dark:group-hover:text-white",
                          )}
                        >
                          {getHumanReadableLabel(intl, item.label)}
                        </h5>

                        <span className="flex justify-center text-sm font-medium text-slate-400 md:justify-start">
                          <FormattedMessage
                            defaultMessage="{count} ativos"
                            id="ZqDEUZ"
                            values={{ count: item.count }}
                          />
                        </span>
                      </div>

                      {(item.value === "BRAZILIAN_COMPANY" || item.value === "BRAZILIAN_REAL_ESTATE_FUND") && (
                        <ChristTheRedeemer className="opacity-50 group-hover:opacity-100" />
                      )}

                      {(item.value === "AMERICAN_COMPANY" || item.value === "AMERICAN_REIT") && (
                        <StatueOfLiberty className="opacity-50 group-hover:opacity-100" />
                      )}
                    </div>
                  </button>
                </div>
              );
            })}
        </div>
      )}

      {viewer && !viewer.isPremium && currentRefinement === null && (
        <FeatureBox
          displayOrigin="Screener"
          buttonText={intl.formatMessage({
            defaultMessage: "Liberar acesso completo",
            id: "tOz+WL",
          })}
          callToActionText={
            <FormattedMessage
              defaultMessage="Turbine suas análises com <strong>dados exclusivos</strong> de mais de 6.000 ativos."
              id="7ACnAd"
              values={{
                strong: (text) => <strong>{text}</strong>,
              }}
            />
          }
          featureHighlights={[
            intl.formatMessage({
              defaultMessage: "Ranking dos melhores ativos",
              id: "JWij0/",
            }),
            intl.formatMessage({
              defaultMessage: "Agenda de Dividendos",
              id: "FS1EGW",
            }),
            intl.formatMessage({
              defaultMessage: "Central de Resultados",
              id: "h5tUH7",
            }),
            intl.formatMessage({
              defaultMessage: "Histórico máximo de dados",
              id: "5jvG0r",
            }),
          ]}
        />
      )}
    </div>
  );
});
