import { useQuery } from "@apollo/client";
import getPlatformPrices from "components/landing/helpers/getPlatformPrices";
import useLandingContents from "components/landing/hooks/useLandingContents";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "components/ui/Accordion";
import classNames from "components/ui/classNames";
import { RadioGroup, RadioGroupItem } from "components/ui/RadioGroup";
import { preserveOnlyUtmParameters } from "lib/analytics/utm";
import type {
  IPremiumPricesQuery,
  IPremiumPricesQueryVariables,
} from "lib/graphql/queries/__generated__/PremiumPricesQuery.generated";
import PremiumPricesQuery from "lib/graphql/queries/PremiumPricesQuery";
import { CheckCircle2Icon } from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ICheckoutProduct } from "types/graphqlTypes";

export default function PlatformPricesSection() {
  const intl = useIntl();
  const router = useRouter();
  const { frequentlyAskedQuestions } = useLandingContents();
  const [planPeriod, setPlanPeriod] = useState<"MONTHLY" | "YEARLY">("MONTHLY");

  const { data, loading } = useQuery<IPremiumPricesQuery, IPremiumPricesQueryVariables>(PremiumPricesQuery, {
    fetchPolicy: "network-only",
    variables: {},
  });

  const platformPrices = getPlatformPrices(intl, data?.premiumPrices || []);

  return (
    <section id="pricing" className="mx-auto flex max-w-7xl flex-col items-center gap-y-12 px-6 py-16 lg:px-0">
      <header className="mx-auto flex flex-col gap-4 text-center">
        <h3 className="mt-2 inline-flex max-w-5xl text-3xl font-bold tracking-tight text-black sm:text-5xl">
          <FormattedMessage
            defaultMessage="Um único plano. O site mais completo com todos os dados que qualquer investidor precisa"
            id="WTm3pV"
          />
        </h3>
      </header>

      <RadioGroup
        loop={false}
        className={classNames(
          "col-span-3 row-start-3",
          "grid w-full grid-cols-4 items-center justify-between rounded-2xl bg-zinc-50/60 p-1",
          "flex max-w-sm justify-normal space-x-2 sm:w-auto",
        )}
        orientation="horizontal"
        defaultValue={planPeriod}
        onValueChange={(value) => setPlanPeriod(value as "MONTHLY" | "YEARLY")}
      >
        <RadioGroupItem
          value="MONTHLY"
          title={intl.formatMessage({ defaultMessage: "Mensal", id: "ie4Tde" })}
          className={classNames(
            "h-12 w-full items-center justify-center rounded-xl text-center sm:w-28",
            "border border-transparent text-sm font-medium tracking-wide text-slate-500",
            "data-[state=checked]:bg-violet-50 data-[state=checked]:font-bold data-[state=checked]:text-violet-600",
            "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-100 focus-visible:ring-offset-1 focus-visible:ring-offset-gray-300",
            "data-[state=checked]:focus-visible:rounded-xl data-[state=checked]:focus-visible:bg-gray-50 data-[state=checked]:focus-visible:text-gray-600",
          )}
        >
          <FormattedMessage defaultMessage="Mensal" id="ie4Tde" />
        </RadioGroupItem>
        <RadioGroupItem
          value="YEARLY"
          title={intl.formatMessage({ defaultMessage: "Anual", id: "nck+Ad" })}
          className={classNames(
            "h-12 w-full items-center justify-center rounded-xl text-center sm:w-28",
            "border border-transparent text-sm font-medium tracking-wide text-slate-500",
            "data-[state=checked]:bg-violet-50 data-[state=checked]:font-bold data-[state=checked]:text-violet-600",
            "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-100 focus-visible:ring-offset-1 focus-visible:ring-offset-gray-300",
            "data-[state=checked]:focus-visible:rounded-xl data-[state=checked]:focus-visible:bg-gray-50 data-[state=checked]:focus-visible:text-gray-600",
          )}
        >
          <FormattedMessage defaultMessage="Anual" id="nck+Ad" />
        </RadioGroupItem>
      </RadioGroup>

      <div className="mx-auto grid max-w-sm items-start gap-6 md:max-w-none md:grid-cols-2">
        {platformPrices.map((node, index) => {
          const isYearlyBlackFriday = node.price && planPeriod === "YEARLY" && node.price?.isYearlyBlackFriday;
          return (
            <div key={node.plan} className="relative h-full">
              {node.price && planPeriod === "YEARLY" && !node.price?.isYearlyBlackFriday && (
                <span className="absolute right-6 top-6 rounded-2xl bg-purple-600/10 p-1.5 px-3 text-xs font-bold uppercase text-purple-600">
                  <FormattedMessage defaultMessage="2 meses grátis" id="vxKW7J" />
                </span>
              )}
              {isYearlyBlackFriday && (
                <span className="absolute right-6 top-6 z-10 rounded-2xl bg-lime-200/10 p-1.5 px-3 text-xs font-bold uppercase text-lime-300">
                  <FormattedMessage defaultMessage="Black Friday 45% OFF!" id="Om64Oi" />
                </span>
              )}
              {node.price && planPeriod === "MONTHLY" && !node.price?.isYearlyBlackFriday && (
                <span className="absolute right-6 top-6 rounded-2xl bg-orange-600/10 p-1.5 px-3 text-xs font-bold uppercase text-orange-600">
                  <FormattedMessage defaultMessage="2 meses grátis no anual" id="ZmFpZQ" />
                </span>
              )}
              {node.price && planPeriod === "MONTHLY" && node.price?.isYearlyBlackFriday && (
                <span className="absolute right-6 top-6 rounded-2xl bg-slate-950 p-1.5 px-3 text-xs font-bold uppercase text-white">
                  <FormattedMessage defaultMessage="Black Friday no anual" id="UqZVpb" />
                </span>
              )}

              <div
                className={classNames("relative flex h-full flex-col rounded-2xl bg-neutral-50 p-6", {
                  "border-transparent bg-brand/5": index === 1,
                  "border-transparent bg-slate-800": isYearlyBlackFriday,
                })}
              >
                <div
                  className={classNames("mb-4 gap-1 text-zinc-900", {
                    "text-brand": index === 1,
                    "text-white": isYearlyBlackFriday,
                  })}
                >
                  {node.price ? (
                    <p className="text-lg font-semibold tracking-tight">{node.plan}</p>
                  ) : (
                    <p className="text-2xl font-black tracking-tight">{node.plan}</p>
                  )}
                  {loading ? (
                    <span
                      className={classNames("h-8 w-full max-w-32 animate-pulse rounded-xl bg-slate-200", "inline-flex")}
                    />
                  ) : (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                      {node.price && (
                        <p className="mb-0.5 inline-flex items-baseline">
                          <span className="text-3xl font-bold">
                            {planPeriod === "MONTHLY" ? node.price.monthly : node.price.yearly}
                          </span>

                          {planPeriod === "MONTHLY" && (
                            <span className="font-medium text-zinc-500">
                              /<FormattedMessage defaultMessage="mês" id="6Zs4WI" />
                            </span>
                          )}
                          {planPeriod === "YEARLY" && (
                            <span
                              className={classNames("font-medium text-zinc-500", {
                                "text-zinc-200": isYearlyBlackFriday,
                              })}
                            >
                              /<FormattedMessage defaultMessage="ano" id="/rC3CD" />
                            </span>
                          )}
                        </p>
                      )}
                    </>
                  )}
                  <p
                    className={classNames("text-zinc-500", {
                      "text-zinc-400": isYearlyBlackFriday,
                    })}
                  >
                    {node.description}
                  </p>
                </div>

                <div className="grow space-y-4">
                  <ul className="list-none space-y-3 text-sm">
                    {node.features.map((feature) => (
                      <li key={feature} className="flex items-center gap-2">
                        <CheckCircle2Icon
                          strokeWidth={2}
                          className={classNames("h-5 w-5 fill-emerald-50/50 text-emerald-600 dark:fill-transparent", {
                            "fill-lime-300/20 text-lime-500": isYearlyBlackFriday,
                          })}
                        />
                        <span
                          className={classNames("font-medium text-slate-600", {
                            "text-slate-300": isYearlyBlackFriday,
                          })}
                        >
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="mt-8">
                  {node.price ? (
                    <Link
                      href={{
                        pathname: "/sign-up",
                        query: {
                          ...preserveOnlyUtmParameters(router.query),
                          checkout:
                            planPeriod === "MONTHLY"
                              ? ICheckoutProduct.MonthlyRecurringSubscription
                              : ICheckoutProduct.YearlyRecurringSubscription,
                        },
                      }}
                      className={classNames(
                        "inline-flex w-full items-center justify-center rounded-xl",
                        "px-4 py-3 text-sm font-medium shadow",
                        "bg-brand text-white hover:opacity-80",
                        { "bg-lime-900/80 text-lime-300": isYearlyBlackFriday },
                      )}
                    >
                      <FormattedMessage
                        description="Botão de conversão na landing page"
                        defaultMessage="Teste grátis por 7 dias"
                        id="nt7ebq"
                      />
                    </Link>
                  ) : (
                    <Link
                      href={{
                        pathname: "/sign-up",
                        query: preserveOnlyUtmParameters(router.query),
                      }}
                      className={classNames(
                        "inline-flex w-full items-center justify-center rounded-xl",
                        "px-4 py-3 text-sm font-medium shadow",
                        "bg-white text-slate-700 hover:bg-emerald-700 hover:text-white hover:opacity-95",
                      )}
                    >
                      <FormattedMessage
                        description="Botão de conversão na landing page"
                        defaultMessage="Cadastre-se"
                        id="19bcRH"
                      />
                    </Link>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <Accordion type="multiple" className="mx-auto w-full max-w-4xl">
        {frequentlyAskedQuestions.map((node) => (
          <AccordionItem key={node.question} value={node.question} className="border-slate-200">
            <AccordionTrigger
              className={classNames(
                "justify-between gap-2 rounded px-4 text-left text-base font-medium text-slate-800 lg:text-lg lg:tracking-wide",
              )}
            >
              {node.question}
            </AccordionTrigger>
            <AccordionContent className="rounded-b px-4 pb-3 text-slate-600">
              <div className="space-y-4">
                {node.answer.map((answer, index) => (
                  <p className="text-base lg:tracking-wide" key={`answer/${String(index)}`}>
                    {answer}
                  </p>
                ))}
              </div>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </section>
  );
}
