import gql from "graphql-tag";

export default gql`
  query SelfieCountry {
    selfie {
      _id
      country
    }
  }
`;
