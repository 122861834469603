/* eslint-disable react/no-unstable-nested-components */
import type { IAuthContext } from "components/auth/Auth";
import { AuthContext } from "components/auth/Auth";
import OverdueSubscriptionStickyBanner from "components/billing/OverdueSubscriptionStickyBanner";
import Landing from "components/landing/Landing";
import IntlWrapper from "components/layouts/IntlWrapper";
import NavbarLayout from "components/layouts/NavbarLayout";
import BackToTop from "components/qol/BackToTop";
import Screener from "components/screener/Screener";
import { getInitialSearchStateFromQuery } from "components/screener/searchState";
import FundamenteiMetadata from "components/seo/FundamenteiMetadata";
import WholePageLoadingBlankslate from "components/shared/WholePageLoadingBlankslate";
import { SearchStateConsumer } from "lib/algolia/SearchStateContext";
import indexes from "lib/algolia/indexes";
import searchClient from "lib/algolia/searchClient";
import SelfieCountryQuery from "lib/queries/SelfieCountryQuery";
import type { NextPage } from "next";
import { ThemeProvider } from "next-themes";
import { useRouter, useSearchParams } from "next/navigation";
import { useContext } from "react";
import type { InstantSearchProps } from "react-instantsearch-core";
import { findResultsState } from "react-instantsearch-dom/server";

interface IndexPageProps {
  indexName: string;
  resultsState?: any;
}

const Index: NextPage<IndexPageProps> = function Index({ indexName, resultsState }) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const { user, loading } = useContext<IAuthContext>(AuthContext);
  if (loading) {
    return <WholePageLoadingBlankslate />;
  }

  if (!user) {
    return (
      <ThemeProvider forcedTheme="light" enableSystem={false} attribute="class" disableTransitionOnChange={true}>
        <Landing />
      </ThemeProvider>
    );
  }

  return (
    <NavbarLayout>
      <FundamenteiMetadata title="Fundamentei" />

      <BackToTop />

      <OverdueSubscriptionStickyBanner />

      <SearchStateConsumer>
        {({ searchState, setSearchState }) => (
          <Screener
            searchClient={searchClient}
            indexName={indexName}
            searchState={searchState}
            resultsState={resultsState}
            onSearchStateChange={setSearchState}
            searchParams={searchParams}
            router={router}
          />
        )}
      </SearchStateConsumer>
    </NavbarLayout>
  );
};

interface ScreenerWithIntlProxyProps extends InstantSearchProps {
  locale?: string;
  country?: string;
}
function ScreenerWithIntlProxy({ locale, ...props }: ScreenerWithIntlProxyProps) {
  // We need to proxy the <IntlWrapper> through the <Screener> component since we won't get a render from the `_app`
  // when using `findResultsState` in `getInitialProps`
  return (
    <IntlWrapper
      locale={{
        id: locale || "pt-BR",
        messages: {},
      }}
    >
      <Screener {...props} />
    </IntlWrapper>
  );
}

Index.getInitialProps = async ({ query, locale, apolloClient }) => {
  const indexName = indexes.issuers;
  const searchState = getInitialSearchStateFromQuery(query);

  const { data } = await apolloClient.query({
    query: SelfieCountryQuery,
  });

  const resultsState = await findResultsState(ScreenerWithIntlProxy, {
    searchClient,
    indexName,
    searchState,
    locale,
    country: data.selfie?.country,
  });

  return {
    indexName,
    resultsState,
  };
};

export default Index;
