import useLandingContents from "components/landing/hooks/useLandingContents";
import classNames from "components/ui/classNames";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/Tabs";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { FormattedMessage } from "react-intl";

export default function PlatformFeaturesSection() {
  const { platformFeatures } = useLandingContents();
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  const [featureDetail, setFeatureDetail] = useState(platformFeatures.secondSection[0].feature);

  return (
    <section id="features" className="space-y-16 bg-slate-900 px-6 py-20 lg:px-0">
      <div className="mx-auto w-full max-w-7xl gap-24 text-center">
        <h2 className="font-mono text-xs/5 font-semibold uppercase tracking-widest text-gray-300">
          <FormattedMessage defaultMessage="De investidor, para investidor" id="2HTNNl" />
        </h2>
        <h3 className="mt-2 inline-flex text-pretty text-3xl font-medium tracking-tighter text-white sm:text-5xl">
          <FormattedMessage defaultMessage="As melhores ferramentas para suas análises" id="20+BIw" />
        </h3>
      </div>

      <div>
        <Tabs
          className="mx-auto flex w-full max-w-7xl flex-col items-center gap-8"
          defaultValue={platformFeatures.secondSection[0].feature}
          value={featureDetail}
          onValueChange={setFeatureDetail}
        >
          <TabsList className="flex flex-col items-start justify-start gap-4 bg-transparent lg:flex-row">
            {platformFeatures.secondSection.map((node) => (
              <TabsTrigger
                key={node.feature}
                value={node.feature}
                onClick={() => {
                  if (!inView && entry) {
                    entry.target.scrollIntoView({ behavior: "smooth", block: "end" });
                  }
                }}
                className={classNames(
                  "w-full justify-start rounded-2xl border border-transparent px-4 py-5",
                  "transition-all duration-200 ease-in-out",
                  "group bg-slate-800 data-[state=active]:bg-lime-900/40 data-[state=inactive]:hover:bg-slate-700",
                )}
              >
                <div className="flex flex-col gap-1 text-left">
                  <div className="mb-2 text-neutral-400 group-data-[state=active]:text-lime-200/90">{node.icon}</div>
                  <h4 className="text-lg font-semibold text-slate-300 group-data-[state=active]:text-lime-200/90">
                    {node.feature}
                  </h4>
                  <p className="text-pretty text-base font-medium text-neutral-400 group-data-[state=active]:text-neutral-300">
                    {node.description}
                  </p>
                </div>
              </TabsTrigger>
            ))}
          </TabsList>

          {platformFeatures.secondSection.map((node) => (
            <TabsContent
              key={node.feature}
              value={node.feature}
              className="relative items-center justify-center border-none bg-transparent p-0"
            >
              <div className="w-7xl w-full overflow-hidden rounded-3xl p-2 lg:h-[727px]">
                <video
                  muted={true}
                  autoPlay={true}
                  preload="auto"
                  loop={true}
                  playsInline={true}
                  className={classNames(
                    "pointer-events-none flex-col items-center justify-center rounded-xl object-contain",
                  )}
                >
                  <source src={node.videoUrl} type="video/mp4" />
                </video>
              </div>
            </TabsContent>
          ))}
        </Tabs>

        <div ref={ref} />
      </div>

      <div className="mx-auto grid max-w-7xl gap-12 px-6 sm:grid-cols-2 lg:grid-cols-3">
        {platformFeatures.thirdSection.map((node) => (
          <div key={node.feature} className="space-y-1">
            <div className="flex items-center space-x-2">
              {node.icon}
              <h3 className="font-semibold text-white">{node.feature}</h3>
            </div>

            <p className="text-pretty text-slate-400">{node.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
